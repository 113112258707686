/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useMemo, useReducer, useRef, useState } from 'react';
import singletonRouter, { useRouter } from 'next/router';
import Script from 'next/script';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Text } from '@contentful/rich-text-types';
import { PRODUCT_TYPES, VTO_SRC } from '@constants';
import {
	DiscountBanner,
	ErrorBoundary,
	Hero,
	HeroSkeleton,
	Modal,
	SEO,
	FrameFiltering,
	VTO,
	Compare,
	InteractiveCardSkeleton,
	InteractiveCard,
	PLPWrapper,
} from '@components';
import { baseFrameReducer, BaseFrameState } from '@utils/hooks/useBaseFrame';
import { useHasMounted, useIsMobile } from '@utils/hooks';
import { capitalizeEachWord, makePossessive } from '@utils/strings';
import {
	ALGOLIA_REFINEMENTS_EYEGLASSES,
	ALGOLIA_REFINEMENTS_SUNGLASSES,
	ALGOLIA_SEARCH_INDEXES,
	FRAME_COLORS,
	LOCALE_DICT,
} from '@utils/index';
import { AdditionalInformationFields, HeroFields, MarketingCardFields, SeoMetadata } from '@ts/contentful';
import { NormalizedCollection } from '@ts/product';
import { COLLECTION_LISTS } from '@constants/contentful';
import styles from './CollectionView.module.scss';

type CollectionViewProps = {
	/**
	 * Base Type for SEO Title
	 *
	 * @note MUST be plural case
	 * @example Women's Eyeglasses
	 */
	baseType: 'Eyeglasses' | 'Sunglasses' | 'Blue Light Glasses';
	collection: NormalizedCollection;
	copyForView: {
		name: string;
		title: string;
		subtitle: string;
	};
	demo: string;
	hero: HeroFields; // Can be null if entry is not found or set to DRAFT in Contentful
	pathInfo: string;
	showVTO: boolean;
	interactiveCards?: MarketingCardFields[];
	seoMetadata?: SeoMetadata;
	additionalInformation?: AdditionalInformationFields;
};

const createURL = ({ qsModule, location, routeState }) => {
	const { origin, pathname, hash } = location;
	const currentParams = qsModule.parse(location.search.slice(1));
	const mergedParams = {
		...(typeof currentParams === 'object' ? currentParams : {}),
		...(typeof routeState === 'object' ? routeState : {}),
	};
	const queryString = qsModule.stringify(mergedParams);
	return queryString ? `${origin}${pathname}?${queryString}${hash}` : `${origin}${pathname}${hash}`;
};

const MultiFilteredCollectionView = ({
	hero,
	demo,
	collection,
	copyForView,
	interactiveCards,
	pathInfo,
	showVTO,
	seoMetadata,
	additionalInformation,
	baseType,
}: CollectionViewProps) => {
	const router = useRouter();
	const isBfroDiscount = useFeatureIsOn('is-bfro-discount');
	const isMobile = useIsMobile();
	const isMounted = useHasMounted();
	const isEyeglasses = baseType === 'Eyeglasses';
	const isPlpCompareTestVariant2Active = typeof window !== 'undefined' && window.isPlpCompareTestVariant2Active;
	const baseFrameProducts = collection.products.filter(p => p.type.includes(PRODUCT_TYPES.BASE_FRAME));
	const [activeSwatchColor, setActiveSwatchColor] = useState('Black');
	const handleSwatchChange = useCallback(payload => {
		if (payload.type === 'frame') {
			setActiveSwatchColor(payload.option);
		}
	}, []);

	const [FitMix, setFitMix] = useState(typeof window === 'undefined' ? null : window?.FitMix);
	const heroRef = useRef<HTMLDivElement>();
	const countryCode = LOCALE_DICT[router.locale].countryCode;
	const seoMeta = seoMetadata
		? {
				title: seoMetadata?.seoTitle,
				description: seoMetadata?.description,
				noindex: seoMetadata?.no_index,
				nofollow: seoMetadata?.no_follow,
				openGraph: {
					title: seoMetadata?.seoTitle,
					description: seoMetadata?.description,
				},
			}
		: {
				title: `${demo === 'all' ? 'All Eyeglasses | Shop Our Collection | Pair Eyewear' : makePossessive(capitalizeEachWord(demo))}`,
			};

	const initialVtoState: BaseFrameState = {
		product: baseFrameProducts[0],
		variant: baseFrameProducts[0].variants[0],
		frame: baseFrameProducts[0].variants[0].option as FRAME_COLORS,
		lens: null,
		image: baseFrameProducts[0].variants[0].image,
	};

	const [vtoState, setVtoState] = useReducer(baseFrameReducer, initialVtoState);

	const memoizedHero = useMemo(() => {
		if (!hero || !isMounted) return null;
		// TODO remove this tag, title attributes. Update in contentful
		const fixedHero = { ...hero, tag: null, title: null, type: 'Full-Bleed' as HeroFields['type'] };

		return (
			<ErrorBoundary skeleton={<HeroSkeleton size={isMobile ? 'small' : 'medium'} asBanner type='Full-Bleed' />}>
				<Hero ref={heroRef} size={isMobile ? 'small' : 'medium'} asBanner {...fixedHero} />
			</ErrorBoundary>
		);
	}, [hero, isMobile, heroRef, isMounted]);

	const cardLocations = interactiveCards?.map(card => card.blockLocation - 1);
	const cardsByLocation = interactiveCards?.reduce(
		(acc, card) => {
			acc[card.blockLocation - 1] = (
				<ErrorBoundary skeleton={<InteractiveCardSkeleton />}>
					<InteractiveCard
						key={card.title}
						background={card.background.url}
						title={card.title}
						text={(card.text.content[0].content[0] as unknown as Text).value}
						subtitle={card.subtitle}
						subheading={!card.shouldExpand ? (card.text.content[0].content[0] as unknown as Text).value : ''}
						shouldExpand={card.shouldExpand}
						buttonClassName={styles['button-container']}
						extraHeaderClassName={styles['header']}
						button1={{
							label: card.buttonLabel1,
							link: card.buttonLink1,
							color: card.buttonColor1,
							fullWidth: true,
						}}
						{...(card.buttonLabel2 && {
							button2: {
								label: card.buttonLabel2,
								link: card.buttonLink2,
								color: card.buttonColor2,
							},
						})}
						data-base-frame-marketing-block={card.title}
						size='flexible'
					/>
				</ErrorBoundary>
			);
			return acc;
		},
		{} as Record<number, JSX.Element>
	);

	return (
		<>
			<Modal>
				<ErrorBoundary>
					<SEO {...seoMeta} />
				</ErrorBoundary>
				{memoizedHero}
				{isBfroDiscount && <DiscountBanner margin='extra' />}
				<PLPWrapper
					isBaseFramePLP={pathInfo.includes('sunglasses') || pathInfo.includes('eyeglasses')}
					{...additionalInformation}
					plpAccordion={undefined}
				>
					<FrameFiltering
						type={isEyeglasses ? 'eyeglasses' : 'sunglasses'}
						indexName={ALGOLIA_SEARCH_INDEXES[countryCode].RECENTLY_ORDERED}
						refinementList={isEyeglasses ? ALGOLIA_REFINEMENTS_EYEGLASSES : ALGOLIA_REFINEMENTS_SUNGLASSES}
						collectionList={COLLECTION_LISTS.ACTIVE}
						plpAccordion={additionalInformation?.plpAccordion}
						router={createInstantSearchRouterNext({
							singletonRouter,
							routerOptions: {
								cleanUrlOnDispose: false,
								createURL,
							},
						})}
						cardLocations={cardLocations}
						cardsByLocation={cardsByLocation}
						collection={collection}
					/>
				</PLPWrapper>
				<Modal.Content
					removePadding
					customClose
					onCloseAutoFocus={e => e.preventDefault()}
					style={{ maxHeight: 'fit-content', maxWidth: '64rem' }}
				>
					<VTO FM={FitMix} state={vtoState} setState={setVtoState} products={baseFrameProducts} pathInfo={pathInfo} />
				</Modal.Content>
			</Modal>
			{isPlpCompareTestVariant2Active && (
				<div className={styles.compareContainer}>
					<Compare
						color={activeSwatchColor}
						product={baseFrameProducts.find(p => p.handle === 'the-larkin')}
						isAccordian={false}
						showTags
						callback={handleSwatchChange}
						isPlp
					/>
				</div>
			)}
			{showVTO && (
				<Script
					src={VTO_SRC}
					type='text/javascript'
					strategy='lazyOnload'
					onLoad={() => {
						setFitMix(window.FitMix);
					}}
				></Script>
			)}
		</>
	);
};

export default MultiFilteredCollectionView;
